import React from "react"
import SEO from "../utils/seo"
import HomeTallersPage from "../components/index/tallers"
// import ContactForm from "../components/index/contactform"
import { graphql } from "gatsby"

import "../components/styles.css"
import "../components/base.css"

import { ParallaxProvider } from "react-scroll-parallax"
import Layout from "../components/layout"
import ContingutPromocionat from "../components/index/contingutPromocionat"
import Featureds from "../components/index/Featureds"
import Clients from "../components/clients";

const IndexPage = ({ data }) => (
  <div className="home page-template-default page page-id-4028 logged-in admin-bar custom-background sidebar-none customize-support">
  <ParallaxProvider>
      <SEO title="Artistes Pedagogs" keywords={[`artistes pedagogs`, `educacio`, `tallers teatre`, `extraescolars`]} />
    <Layout>
      <div className="features" id="features">
        <Featureds data={data.featureds} />
      </div>
      {data.promoted && <ContingutPromocionat data={data.promoted} />}
      <HomeTallersPage data={data.tallers} loadMore={data.mestallers} headed />
      <Clients clients={data.clients} />
    </Layout>
  </ParallaxProvider>
  </div>
)
export default IndexPage

export const query = graphql`
  query IndexQuery {
    mestallers: contentfulAsset(title: {eq: "mestallers"}) {
      title
      fixed(width: 385, height: 255) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
    }
    tallers: allContentfulTallers(limit: 5, filter: {promoted: {eq: true}}) {
      edges {
        node {
          id
          name
          short_description
          color
          slug
          imageFilter
          image {
            title
            fixed(width: 385, height: 255) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    clients: allContentfulClients {
      edges {
        node {
          nom
          image {
            fluid (maxWidth: 150) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            file {
              url
            }
          }
        }
      }
    }
    promoted: allContentfulContingutPromocionat {
      edges {
        node {
          youtubeUrl
          titol
          imatge {
            fluid (maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          textDelBoto
          text_millorat {
            json
          }
          enllaDelBot
        }
      }
    }
    featureds: allContentfulFeatureds (sort: { fields: ordre }) {
      edges {
        node {
          titol
          text {
            text
          }
          icona
          id
        }
      }
    }
  }
`
