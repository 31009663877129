import React from "react"
import richText from '../../utils/richText'
import Img from "gatsby-image"

const ContingutPromocionat = ({ data }) => {
  const contingut = data.edges[0].node
  const descripcio = richText(contingut.text_millorat.json)

  return (
    <div style={{ display: "block" }}>
      <div style={{ padding: '30px 0' }} className="secondary-color-bg">
        <div className="row" style={{ maxWidth: 1000, margin: "20px auto" }}>
          <div
            className="col2 column"
            style={{ textAlign: "center", margin: 10 }}
          >
            <h1 style={{ color: "white", margin: "20px 0", fontFamily: "Days One" }}>
              {contingut.titol}
            </h1>
            <p style={{ color: 'white' }}>{descripcio}</p>
            <br />
            <br />
            <a target="_blank" rel="noopener noreferrer" href={contingut.enllaDelBot}>
              <div
                className="tagline-link button button-medium menu-contrast-button"
                id="inscripcions-obertes"
              >
                {contingut.textDelBoto}
              </div>
            </a>
          </div>
          <div className="col2 column right" style={{ margin: 10 }}>
            {contingut.youtubeUrl.length > 2 ? (
              <iframe
                width="700"
                id="promoted_video"
                title="promoted_video"
                height="315"
                src={contingut.youtubeUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
                <Img fluid={contingut.imatge.fluid} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContingutPromocionat
