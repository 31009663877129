import React from "react"
import Slider from "react-slick"
// import Img from "gatsby-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

var settings = {
  dots: true,
  infinite: true,
  slidesToShow: 8,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
function Clients({ clients }) {
  return (
    <div id="clients" className="clients" style={{ marginBottom: 100 }}>
      <div className="clients-heading section-heading">
        Clients i col·laboradors
      </div>
      <Slider {...settings}>
        {clients.edges.map(({ node: client }) => (
          <div
            key={client.nom}
            className="clientsSlideItem"
          >
            <img
              alt={client.nom}
              src={client.image.file.url}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Clients
